<template>
  <div>

    <div class="" v-if=" user.country == 'MX' ">
      <div class="row align-items-center mb-1">
        <div class="col">
          <p class="f-14"> <strong v-text="$t('dashboard.payments.bankAccounts')"></strong> </p>
        </div>
        <div class="col-auto">
          <button class="btn btn-success circle-25 p-0" @click="addNewMethod('clabe')">
            <i class="fa fa-plus-circle "></i>
          </button>
        </div>
      </div>
      <div v-if="getMethodsClabe.length > 0">
        <table class="table table-striped">
          <thead>
            <tr>
            </tr>
          </thead>
          <tbody>
            <tr v-for=" method in getMethodsClabe " :key="method.id">
              <td >
                <div class="d-flex justify-content-between align-items-center">
                  <button class="btn p-0 text-success text-truncate" @click="editMethod(method.id)">
                    <strong v-text=" 'Alias: ' + (method.name ? method.name : $t('tables.none')) "></strong>
                  </button>
                  <button class="btn btn-danger circle-20 btn-xs mr-1" @click="fnApiDeleteMethod(method.id)">
                    <i class="fa fa-trash-alt  "></i>
                  </button>
                </div>
                <div class="row justify-content-between ">
                  <div class="col-6">
                    <p>
                      <small v-text=" 'Clabe: ' "></small>
                      <small v-text=" fnAccountNumber(method.number) "></small>
                    </p>
                  </div>
                  <div class="col-6 text-right">
                    <p class="text-truncate" v-if="fnGetBank(method.bank)">
                      <small v-text=" $t('general.form.bank') + ': ' + fnGetBank(method.bank) "></small>
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
      <div class="d-flex my-2" v-else>
        <div class="linedoted mt-auto"></div>
        <p class="m-0 text-muted" v-text="$t('dashboard.payments.noBankAccounts')"></p>
      </div>
      <hr>
    </div>
    <div class="" v-if="user.country == 'MX'">
      <div class="row align-items-center mb-1">
        <div class="col">
          <p> <strong v-text="$t('dashboard.payments.cards')"></strong> </p>
        </div>
        <div class="col-auto">
          <button class="btn btn-success circle-25 p-0" @click="addNewMethod('debit')">
            <i class="fa fa-plus-circle "></i>
          </button>
        </div>
      </div>

      <div v-if="getMethodsDebit.length > 0">

        <table class="table table-striped">
          <thead>
            <tr>
            </tr>
          </thead>
          <tbody>
            <tr v-for=" method in getMethodsDebit " :key="method.id">
              <td >
                <div class="d-flex justify-content-between align-items-center">
                  <button class="btn p-0 text-success text-truncate" @click="editMethod(method.id)">
                    <strong v-text=" 'Alias: ' + (method.name ? method.name : $t('tables.none')) "></strong>
                  </button>
                  <button class="btn btn-danger circle-20 btn-xs mr-1" @click="fnApiDeleteMethod(method.id)">
                    <i class="fa fa-trash-alt  "></i>
                  </button>
                </div>
                <div class="row justify-content-between ">
                  <div class="col-6">
                    <p>
                      <small v-text=" 'Clabe: ' "></small>
                      <small v-text=" fnAccountNumber(method.number) "></small>
                    </p>
                  </div>
                  <div class="col-6">
                    <p class="text-truncate text-right" v-if="fnGetBank(method.bank)">
                      <small v-text=" $t('general.form.bank') + ': ' "></small>
                      <small v-text="fnGetBank(method.bank)"></small>
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex my-2" v-else>
        <div class="linedoted mt-auto"></div>
        <p class="m-0 text-muted" v-text="$t('dashboard.payments.noCards')"></p>
      </div>
      <hr>
    </div>

    <div class="" v-show="user.country !== 'MX'">
      <div class="row align-items-center mb-1">
        <div class="col">
          <p> <strong>Paypal</strong></p>
        </div>
        <div class="col-auto">
          <button class="btn btn-success circle-25 p-0" @click="addNewMethod('paypal')">
            <i class="fa fa-plus-circle "></i>
          </button>
        </div>
      </div>
      <div v-if="getMethodsPaypal.length > 0">
        <table class="table table-striped">
          <thead>
            <tr>
            </tr>
          </thead>
          <tbody>
            <tr v-for=" method in getMethodsPaypal " :key="method.id">
              <td >
                <div class="d-flex justify-content-between align-items-center ">
                  <button class="btn p-0 text-success text-truncate" @click="editMethod(method.id)">
                    <strong v-text=" 'Alias: ' + (method.name ? method.name : $t('tables.none')) "></strong>
                  </button>
                  <button class="btn btn-danger circle-20 btn-xs mr-1 p-" @click="fnApiDeleteMethod(method.id)">
                    <i class="fa fa-trash-alt  "></i>
                  </button>
                </div>
                <p class="m-0">
                  <small v-text=" $t('general.form.email') + ': ' "></small>
                  <small v-text="method.email "></small>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex my-2" v-else>
        <div class="linedoted mt-auto"></div>
        <p class="m-0 text-muted" v-text="$t('dashboard.payments.noPaypalAccounts')"></p>
      </div>
      <hr>
    </div>

    <b-modal :visible="modals.paymentMethod" :title="$t('dashboard.payments.newAccount')" content-class="card form-rounded-inputs card-ecart " centered
      header-class="card-header" @hidden="resetPaymentMethod()" cancel-variant="danger" size="md" hide-header-close>
      <v-observer tag="form" @submit.prevent="fnAddNewPayment()" ref="methodForm">
        <div class="row">
          <div class="col-12" v-show="integration || showMethodType ">
            <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.paymentType') ">
              <div class="form-group">
                <label for="" v-text=" $t('general.form.paymentType') "> </label>
                <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                  <select class="custom-select text-capitalize" v-model="newMethod.method" :class="{'is-invalid': errors.length > 0}">
                    <option :value="null" v-text="$t('general.form.select')"></option>
                    <option :value="method" v-for="(method,index) in paymentMethods" :key="index" v-text="method"></option>
                  </select>
                  <div class="input-group-append">
                    <label class="input-group-text"> <i class="fa fa-hand-pointer"></i> </label>
                  </div>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </v-validation>
          </div>
          <div class="col-12">
            <v-validation rules="required|min:3" v-slot="{ errors }" :name=" $t('general.form.paymentAlias') ">
              <div class="form-group">
                <label for="" class="w-100 " v-text=" $t('general.form.paymentAlias') "> </label>
                <div class="input-group ">
                  <input type="text" class="form-control" v-model="newMethod.name"
                    :class=" { 'is-invalid':errors.length > 0 } " :placeholder="$t('placeholders.paymentAlias')">
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right"> <strong>
                        <i class=" fa fa-keyboard "></i>
                      </strong> </span>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </v-validation>
          </div>
          <!-- Methods -->
          <div class="col-12" v-show=" newMethod.method == 'paypal' && user.country !== 'MX'">

            <v-validation :rules=" 'email|' + (newMethod.method == 'paypal' ? 'required' : '') " v-slot="{ errors }"
              :name=" $t('dashboard.payments.paypalAccount') ">
              <div class="form-group">
                <label v-text=" $t('dashboard.payments.paypalAccount') "> </label>
                <div class="input-group ">
                  <input type="email" class="form-control" v-model="newMethod.email"
                    :class=" { 'is-invalid':errors.length > 0 } " placeholder="example@example.com">
                  <div class="input-group-append">
                    <span class="input-group-text rounded-right"> <strong>
                        <i class="fa fa-at"></i>
                      </strong>
                    </span>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0">
                    {{ errors[0] }}
                  </div>
                </div>
              </div>
            </v-validation>

          </div>

          <div class="col-12" v-if="newMethod.method == 'debit'">
            <div class="row">

              <div class="col-12">
                <v-validation :rules=" (newMethod.method == 'debit' ? 'required' : '') " v-slot="{ errors }"
                  :name=" $t('general.form.cardholderName') ">
                  <div class="form-group">
                    <label for="" class="w-100 " v-text=" $t('general.form.cardholderName') "> </label>
                    <div class="input-group ">
                      <input type="text" maxlength="18" class="form-control" v-model="newMethod.business_name"
                        :placeholder="$t('placeholders.cardholderName')" :class=" { 'is-invalid':errors.length > 0 } ">
                      <div class="input-group-append">
                        <span class="input-group-text rounded-right"> <strong>
                            <i class=" fa fa-user "></i>
                          </strong> </span>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </div>
                </v-validation>

              </div>

              <div class="col-12 col-md-6">

                <v-validation :rules="'min:16|' + (newMethod.method == 'debit' ? 'required' : '') " v-slot="{ errors }"
                  :name=" $t('dashboard.payments.cardNumber') ">
                  <div class="form-group">
                    <label for="" class="w-100 " v-text=" $t('dashboard.payments.cardNumber') "> </label>
                    <div class="input-group ">
                      <the-mask v-model="newMethod.number" class="form-control" type="text" mask="####-####-####-####"
                        placeholder="1234-1234-1234-4099" :class=" { 'is-invalid':errors.length > 0 } " />
                      <div class="input-group-append">
                        <span class="input-group-text rounded-right"> <strong>
                            <i class=" fa fa-credit-card "></i>
                          </strong> </span>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </div>
                </v-validation>
              </div>

              <div class="col-12 col-md-6">

                <v-validation :rules=" (newMethod.method == 'debit' ? 'required' : '') " v-slot="{ errors }"
                  :name=" $t('dashboard.payments.bank') ">
                  <div class="form-group">
                    <label for="" v-text="$t('dashboard.payments.bank')"></label>
                    <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                      <select class="custom-select" :class=" { 'is-invalid':errors.length > 0 } "
                        v-model="newMethod.bank">
                        <option :value="null" v-text=" $t('general.form.select') ">
                        </option>
                        <option :value="bank.clave" v-for="  ( bank, index ) in banksList  " :key="index"
                          v-text="bank.name">
                        </option>
                      </select>
                      <div class="input-group-append">
                        <label class="input-group-text"> <i class="fa fa-university"></i> </label>
                      </div>
                    </div>
                    <div class="invalid-feedback" v-if="errors.length > 0">
                      {{ errors[0] }}
                    </div>
                  </div>
                </v-validation>
              </div>
            </div>
          </div>

          <div class="col-12" v-if=" newMethod.method == 'clabe'">
            <div class="row">
              <div class="col-12">
                <v-validation :rules="'min:18|' + (newMethod.method == 'clabe' ? 'required' : '')" v-slot="{ errors }"
                  :name="'Clabe'">
                  <div class="form-group">
                    <label v-text="'Clabe'"> </label>
                    <div class="input-group ">
                      <the-mask v-model="newMethod.number" class="form-control" type="text" mask="###-###-###########-#"
                        placeholder="011-222-33333333333-4" :class=" { 'is-invalid':errors.length > 0 } " />
                      <div class="input-group-append">
                        <span class="input-group-text rounded-right"> <strong>
                            <i class="fa fa-credit-card"></i>
                          </strong>
                        </span>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </div>
                </v-validation>

              </div>
              <div class="col-12 col-md-6">
                <v-validation :rules=" (newMethod.method == 'clabe' ? 'required|min:3' : '') " v-slot="{ errors }"
                  :name=" $t('general.form.businessName') ">
                  <div class="form-group">
                    <label v-text=" $t('general.form.businessName') "> </label>
                    <div class="input-group ">
                      <input type="text" class="form-control" v-model="newMethod.business_name"
                        :class=" { 'is-invalid':errors.length > 0 } " :placeholder="$t('general.form.businessName')">
                      <div class="input-group-append">
                        <span class="input-group-text rounded-right"> <strong>
                            <i class=" fa fa-keyboard "></i>
                          </strong> </span>
                      </div>
                      <div class="invalid-feedback" v-if="errors.length > 0">
                        {{ errors[0] }}
                      </div>
                    </div>
                  </div>
                </v-validation>
              </div>

              <div class="col-12 col-md-6">
                <v-validation :rules=" (newMethod.method == 'clabe' ? 'required' : '') " v-slot="{ errors }"
                  :name=" $t('dashboard.payments.bank') ">
                  <div class="form-group">
                    <label for="" v-text="$t('dashboard.payments.bank')"></label>
                    <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                      <select class="custom-select" :class=" { 'is-invalid':errors.length > 0 } "
                        v-model="newMethod.bank">
                        <option :value="null" v-text=" $t('general.form.select') ">
                        </option>
                        <option :value="bank.clave" v-for="  ( bank, index ) in banksList  " :key="index"
                          v-text="bank.name">
                        </option>
                      </select>
                      <div class="input-group-append">
                        <label class="input-group-text"> <i class="fa fa-university"></i> </label>
                      </div>
                    </div>
                    <div class="invalid-feedback" v-if="errors.length > 0">
                      {{ errors[0] }}
                    </div>
                  </div>
                </v-validation>
              </div>

            </div>
          </div>
        </div>
        <input type="submit" hidden>
      </v-observer>

      <template v-slot:modal-footer>
        <p>
          <button @click=" resetPaymentMethod() " class="btn" v-text=" $t('general.button.close') "></button>
          <button class=" btn btn-success" :disabled="loading.sendingNewMethod" @click="fnAddNewPayment()">
            <span v-text=" newMethod.id ? $t('general.button.update') : $t('general.button.add')  "
              v-if=" !loading.sendingNewMethod "></span>
            <i class="fa fa-spinner fa-spin " v-else></i>
          </button>
        </p>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";
  export default {
    data() {
      return {
        // modalMethods: false,
      };
    },
    computed: {
      ...mapState(['integration']),
      ...mapState('session', ['user']),
      ...mapState('payments', ['newMethod', 'banksList', 'modals', 'loading', 'showMethodType']),
      ...mapGetters('payments', ['getMethodsPaypal', 'getMethodsDebit', 'getMethodsClabe', 'getBalancesFilter',
        'getTotalBalances'
      ]),
      paymentMethods() {
        if (this.user.country == 'MX') {
          // return ['paypal', 'debit', 'clabe']; TODO: Add this line if paypal will integrate again.
          return ['debit', 'clabe'];
        } else {
          return ['paypal'];
        }
      }
    },
    methods: {
      ...mapMutations('payments', ['resetPaymentMethod', 'changeModal', 'editMethod']),
      ...mapActions('payments', ['fnApiAddNewMethod', 'fnApiDeleteMethod']),
      addNewMethod(type) {
        this.newMethod.method = type;
        this.changeModal({
          paymentMethod: true
        });
      },
      updateMethod(method) {
        this.newMethod = method;
        this.changeModal({
          paymentMethod: true
        });
      },
      async fnAddNewPayment() {
        if (await this.$refs.methodForm.validate()) {
          await this.fnApiAddNewMethod(this.newMethod);
          if (this.integration) {
            this.$emit('user-complete');
          }
        } else {}
      },

      fnGetBank(bankId) {
        let tempBank = this.banksList.find(bank => bank.clave == bankId);
        if (tempBank) {
          return tempBank.name;
        }
        return null;
      }

    },
    mounted() {}
  }
</script>

<style>

</style>